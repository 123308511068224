import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import SeriesList from 'src/components/organisms/series/SeriesList';
import { ISeriesMap } from 'src/interfaces/template';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {
  seriesMap: ISeriesMap;
}

const SeriesListTemplate: FC<PropsType> = ({ seriesMap }) => {
  const title = 'Series Archive';
  return (
    <Layout>
      <SEO title={title} />
      <SeriesList title={title} seriesMap={seriesMap} />
    </Layout>
  );
};

export default SeriesListTemplate;
