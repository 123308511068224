import React, { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import SeriesCard from 'src/components/molecules/series/SeriesCard';
import { ISeriesMap } from 'src/interfaces/template';
import PageHeader from 'src/components/organisms/common/PageHeader';

interface PropsType {
  title: string;
  seriesMap: ISeriesMap;
}

const SeriesList: FC<PropsType> = ({ title, seriesMap }) => {
  const series = useMemo(() => getSeries(seriesMap), [seriesMap]);
  return (
    <>
      <PageHeader title={title} />
      <SeriesBox>{series}</SeriesBox>
    </>
  );
};

export default SeriesList;

function getSeries(seriesMap: ISeriesMap): ReactNode {
  if (!seriesMap) {
    return <></>;
  }

  return Object.keys(seriesMap).map(key => {
    const series = seriesMap[key];
    return <SeriesCard key={series.slug} series={series} />;
  });
}

const SeriesBox = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 gap-4 grid-flow-row auto-rows-max mx-2 my-4`}
`;
