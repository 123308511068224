import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import tw from 'twin.macro';

import { ISeries } from 'src/interfaces/template';

interface PropsType {
  series: ISeries;
}

const SeriesCard: FC<PropsType> = ({ series }) => {
  return (
    <Container>
      <Heading>
        <Link to={`${series.slug}`}>
          {series.name}({series.posts.length})
        </Link>
      </Heading>
      <Body>
        {series.posts.map(post => (
          <Content key={post.slug}>
            <Link to={post.slug}>{post.title}</Link>
          </Content>
        ))}
      </Body>
    </Container>
  );
};

export default SeriesCard;

const Container = styled.div`
  ${tw`rounded-md bg-gray-100 dark:bg-gray-700 py-4 m-2 overflow-hidden`}
`;

const Heading = styled.div`
  ${tw`px-3 py-2 mx-3 mb-2 font-bold text-gray-800 dark:text-gray-200 hover:text-black hover:dark:text-white bg-gray-300 dark:bg-gray-600 rounded-md`}

  a {
    ${tw`text-lg`}
  }
`;

const Body = styled.div`
  ${tw`w-full max-h-[300px] overflow-auto`}
`;

const Content = styled.div`
  ${tw`flex items-center px-4 py-2 text-gray-700 dark:text-gray-300 hover:text-gray-900 hover:dark:text-gray-100 border-gray-400 hover:bg-gray-300 dark:bg-gray-700 hover:dark:bg-gray-600 cursor-pointer`}

  a {
    ${tw`flex-1 text-base`}
  }
`;
