import React, { FC, memo } from 'react';

import { ITemplateProps, ISeriesMap } from 'src/interfaces/template';
import SeriesListTemplate from 'src/components/templates/series/SeriesListTemplate';

interface PropsType
  extends ITemplateProps<{
    seriesMap: ISeriesMap;
  }> {}

const SeriesListPageTemplate: FC<PropsType> = memo(({ pageContext }) => {
  return <SeriesListTemplate seriesMap={pageContext.seriesMap} />;
});

export default SeriesListPageTemplate;
